import React, { forwardRef, Ref } from "react";
import clsx from "clsx";

type Props = JSX.IntrinsicElements["input"];

export const Input = forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
  return (
    <input
      {...props}
      type="text"
      className={clsx("Mixin_Textbox", "leading-6")}
      ref={ref}
    />
  );
});
