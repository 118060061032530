import React, { PropsWithChildren } from "react";
import * as styles from "./index.module.scss";
import clsx from "clsx";

type Background =
  | "white"
  | "subtle-gray"
  | "light-gray"
  | "flatt-red-light"
  | "brand"
  | "kenro-green"
  | "navy";

type Props = {
  background?: Background;
  className?: string;
};

export const Section = (props: PropsWithChildren<Props>) => {
  const { children, background } = props;

  if (!background) {
    return <div className={clsx(styles.Section, styles.White)}>{children}</div>;
  }

  switch (background) {
    case "white":
      return (
        <div className={clsx(styles.Section, styles.White)}>{children}</div>
      );
    case "subtle-gray":
      return (
        <div className={clsx(styles.Section, styles.SubtleGray)}>
          {children}
        </div>
      );
    case "light-gray":
      return (
        <div className={clsx(styles.Section, styles.LightGray)}>{children}</div>
      );
    case "flatt-red-light":
      return (
        <div className={clsx(styles.Section, styles.SubtleBrand)}>
          {children}
        </div>
      );
    case "brand":
      return (
        <div className={clsx(styles.Section, styles.Brand)}>{children}</div>
      );
    case "kenro-green":
      return (
        <div className={clsx(styles.Section, styles.KenroGreen)}>
          {children}
        </div>
      );
    case "navy":
      return (
        <div className={clsx(styles.Section, styles.Navy)}>{children}</div>
      );
    default:
      return (
        <div className={clsx(styles.Section, styles.White)}>{children}</div>
      );
  }
};

export const SubSection = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  return <div className={styles.SubSection}>{children}</div>;
};
