import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { Button } from "~components/utils/button";
import { MetaProps } from "~components/templates/seo";
import * as styles from "./index.module.scss";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section } from "~components/templates/section";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { DocumentStyle } from "~components/templates/document-style";
import { Input } from "~components/utils/input";
import clsx from "clsx";

type Props = {
  data: GatsbyKenro2023NewGradsQuizPageQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title:
      "新卒開発エンジニアへのセキュアコーディング挑戦状 | KENRO (ケンロー) by Flatt Security",
    image: `https://flatt.tech${data?.ogp?.publicURL || ""}`,
    description:
      "Web開発エンジニアとして就職予定の皆さんに是非知ってほしいセキュアコーディングの基礎知識のクイズを3問用意しました。全問正解して、同期や友達にシェアしよう!",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  // Disclaimer
  console.log(
    "このメッセージを見ているあなたの想像通り、1問目と2問目はクライアント側のJavaScriptで正誤処理を行なっているので、正答をソースコードから突き止めることは可能です。クライアントで処理を行うことはセキュリティ上のアンチパターンになり得ますが、4択のクイズなんて4回解答すれば当たるのだからそこにコストをかけてもあまり意味はありません。ビジネス上、何が守るべきロジックかということは適宜人間が考える必要があるわけですが、そんな風にセキュアコーディングに興味があるあなたは、ぜひ今すぐKENROのトライアルに登録してください！ https://flatt.tech/kenro から登録可能です！",
  );

  // Quiz1に関する記述

  const quiz1Code = `const http = require('node:http');
const {URL} = require('node:url');
const portNum = 8082;
const host = 'localhost';
const server = http.createServer(function(request, response) {
    const url = new URL(\`http://\${host}:\${portNum}\${request.url}\`);
    const name = url.searchParams.get('name') || "";

    response.writeHead(200, {'Content-Type': 'text/html; charset=utf-8'});
    if(name === "") {
        response.end('<h1>誰かさん、お誕生日おめでとう！！</h1>');
    }else{
        response.end(\`<h1>\${decodeURI(name)}さん、お誕生日おめでとう！！</h1>\`);
    }
});

console.log(\`access http:/\${host}:\${portNum}\`);

server.listen(portNum);`;

  const quiz1Options = [
    {
      id: "1a",
      text: "攻撃者がnameパラメータに<script>タグを入力したURLを作成し、サイトAのユーザにアクセスさせることで、ユーザのブラウザ上で任意のスクリプトを実行させられてしまう。",
    },
    {
      id: "1b",
      text: "攻撃者がnameパラメータにHTTPレスポンスヘッダを改ざんする文字列を入力したURLを作成し、サイトAのユーザにアクセスさせることで、ユーザのブラウザ上で任意のスクリプトを実行させられてしまう。",
    },
    {
      id: "1c",
      text: "攻撃者がパラメータにサイトAのサーバー上のリソースを指定することで、攻撃者のブラウザにそのデータが送信されてしまい機密情報が漏洩する。",
    },
    {
      id: "1d",
      text: "攻撃者がパラメータにシェル上でコマンドを実行するような値を入力することで、サイトAのサーバー上で任意のコードを実行されてしまう。",
    },
  ];

  const quiz1SubmitAreaTsx = {
    default: (
      <>
        <div className={styles.MessageAndButtonWrapper}>
          <Button
            buttonSize="Large"
            className={styles.PrimaryButton}
            type="submit"
          >
            解答を送信
          </Button>
        </div>
      </>
    ),
    failed: (
      <>
        <div className={styles.MessageAndButtonWrapper}>
          <p className={styles.FailedMessage}>残念！不正解です</p>
          <Button
            buttonSize="Large"
            className={styles.PrimaryButton}
            type="submit"
          >
            解答を送信
          </Button>
        </div>
      </>
    ),
    success: (
      <>
        <div className={styles.Quiz_SuccessWrapper}>
          <DocumentStyle>
            <OptionalGatsbyImage
              className={styles.Quiz_Success}
              data={data.quiz_success}
              alt="挑戦成功！"
            />
          </DocumentStyle>
          <DocumentStyle>
            <h2 className={styles.Heading}>
              <div>
                <span>入力値がDOMに</span>
                <span>反映される</span>
                <span>アプリケーション</span>
                <span>ではXSS</span>
                <span>(クロスサイト</span>
                <span>スクリプティング)</span>
                <span>のリスクが</span>
                <span>存在します。</span>
              </div>
            </h2>
            <p>
              クエリパラメータなど、ユーザが自由に入力できる値がDOMに反映され、かつ適切な対処がなされていない時、&lt;script&gt;タグなどを用いて攻撃者にJavaScriptを注入されると、被害者のブラウザ上で任意のJavaScriptコードが実行可能となりCookieの窃取など様々なリスクにつながります。
              <a
                href="https://blog.flatt.tech/entry/xss_risk"
                target="_blank"
                rel="noreferrer"
              >
                XSSのさまざまなリスクを解説したブログ記事
              </a>
              もぜひご覧ください。
            </p>
          </DocumentStyle>
        </div>
      </>
    ),
  };

  const [quiz1Answer, setQuiz1Answer] = useState("");
  const [quiz1SubmitArea, setQuiz1SubmitArea] = useState(
    quiz1SubmitAreaTsx.default,
  );
  const [quiz2Dsiplay, setQuiz2Display] = useState();

  function handleChange1(e) {
    setQuiz1Answer(e.target.value);
  }

  function handleSubmit1(e) {
    e.preventDefault();
    if (quiz1Answer === "1a") {
      setQuiz1SubmitArea(quiz1SubmitAreaTsx.success);
      setQuiz2Display(true);
    } else {
      setQuiz1SubmitArea(quiz1SubmitAreaTsx.failed);
    }
  }

  // Quiz2に関する記述

  const quiz2Options = [
    {
      id: "2a",
      text: "外部の立場から、特定のメールアドレスがそのサービスへ登録されているかどうか確認できる。",
    },
    {
      id: "2b",
      text: "ログイン後に攻撃者の用意したページへ遷移させられフィッシング詐欺等の被害につながってしまう。",
    },
    {
      id: "2c",
      text: "認証を回避してログインができてしまう。",
    },
    {
      id: "2d",
      text: "ログイン後のセッションIDが推測できてしまい、なりすまし被害等につながる。",
    },
  ];

  const quiz2SubmitAreaTsx = {
    default: (
      <>
        <div className={styles.MessageAndButtonWrapper}>
          <Button
            buttonSize="Large"
            className={styles.PrimaryButton}
            type="submit"
          >
            解答を送信
          </Button>
        </div>
      </>
    ),
    failed: (
      <>
        <div className={styles.MessageAndButtonWrapper}>
          <p className={styles.FailedMessage}>残念！不正解です</p>
          <Button
            buttonSize="Large"
            className={styles.PrimaryButton}
            type="submit"
          >
            解答を送信
          </Button>
        </div>
      </>
    ),
    success: (
      <>
        <div className={styles.Quiz_SuccessWrapper}>
          <DocumentStyle>
            <OptionalGatsbyImage
              className={styles.Quiz_Success}
              data={data.quiz_success}
              alt="挑戦成功！"
            />
          </DocumentStyle>
          <DocumentStyle>
            <h2 className={styles.Heading}>
              <div>
                <span>リダイレクト先を</span>
                <span>自由に指定</span>
                <span>できてしまうと</span>
                <span>オープンリダイレクト</span>
                <span>につながります。</span>
              </div>
            </h2>
            <p>
              リダイレクト先がクエリパラメータなど外部から操作できる値で決定されている場合、適切な対策を施さないと外部の悪意あるサイトへのリダイレクトを許してしまいます。攻撃者の用意したパラメータ付きのURLでログインしてしまったがために、ユーザがフィッシングサイトに誘導されてしまうようなリスクは防ぐべきでしょう。
            </p>
          </DocumentStyle>
        </div>
      </>
    ),
  };

  const [quiz2Answer, setQuiz2Answer] = useState("");
  const [quiz2SubmitArea, setQuiz2SubmitArea] = useState(
    quiz2SubmitAreaTsx.default,
  );
  const [quiz3Display, setQuiz3Display] = useState();

  function handleChange2(e) {
    setQuiz2Answer(e.target.value);
  }

  function handleSubmit2(e) {
    e.preventDefault();
    if (quiz2Answer === "2b") {
      setQuiz2SubmitArea(quiz2SubmitAreaTsx.success);
      setQuiz3Display(true);
    } else {
      setQuiz2SubmitArea(quiz2SubmitAreaTsx.failed);
    }
  }

  // Quiz3に関する記述

  const quiz3Code1 = `CREATE TABLE \`item_list\` (
    \`name\` TEXT NOT NULL,
    \`price\` INTEGER NOT NULL,
    \`available\` BOOLEAN NOT NULL
) ENGINE=InnoDB DEFAULT CHARSET=utf8;`;

  const quiz3Code2 = `SELECT name, price FROM item_list WHERE name LIKE '%(ユーザからの入力値)%' AND available = TRUE`;
  const [quiz3Answer, setQuiz3Answer] = useState("");
  const [quiz3Result, setQuiz3Result] = useState(false);
  const [quiz3ResultDisplay, setQuiz3ResultDisplay] = useState(false);

  type Quiz3Result = {
    isCorrect: boolean;
  };
  function handleChange3(e) {
    setQuiz3Answer(e.target.value);
  }
  async function handleSubmit3(e) {
    e.preventDefault();
    const input = quiz3Answer;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ answer: input }),
    };
    const response = await fetch(
      "https://kenro-lp-judge.flatt.training/",
      requestOptions,
    );
    const judge = await response.json();
    setQuiz3Result(judge["isCorrect"]);
    setQuiz3ResultDisplay(true);
  }
  useEffect(() => {
    if (quiz3Result) {
      window.location.href = "/kenro/2023-new-grads-quiz/congratulations";
    }
  });

  return (
    <Kenro2PaneLayout meta={meta} isSimple="true">
      <div className={styles.Wrapper}>
        <div className={styles.Top}>
          <OptionalGatsbyImage
            className={styles.Top_MainImage}
            data={data.main}
            alt="新卒開発エンジニアへのセキュアコーディング挑戦状 PRESENTED BY KENRO"
          />
        </div>
        <div className={styles.Introduction}>
          <Section background="kenro-green">
            <Common2PaneSectionLayout>
              <OptionalGatsbyImage
                className={styles.Introduction_Decoration}
                data={data.numbering}
                alt=""
              />
              <DocumentStyle>
                <p className={styles.Introduction_Description}>
                  株式会社Flatt
                  Securityから、Web開発エンジニアとして就職予定の皆さんに是非知ってほしい
                  セキュアコーディングの基礎知識のクイズを3問用意しました。
                  全問正解して、同期や友達にシェアしよう！
                </p>
              </DocumentStyle>
            </Common2PaneSectionLayout>
          </Section>
        </div>
        <div className={styles.Quiz1}>
          <Section>
            <Common2PaneSectionLayout>
              <DocumentStyle>
                <h2 className={styles.Heading}>
                  <OptionalGatsbyImage
                    className={styles.Heading_Image}
                    data={data.heading1}
                    alt="挑戦 その1"
                  />
                  <div>
                    <span>クエリパラメータの値が</span>
                    <span>DOMに</span>
                    <span>反映される</span>
                    <span>アプリケーション</span>
                  </div>
                </h2>
                <p>
                  以下のように、クエリパラメータの入力値がDOMに反映される機能がとあるWebサイト（以下、サイトA）で提供されていました。
                </p>
                <OptionalGatsbyImage
                  className={styles.Quiz_Image}
                  data={data.quiz1_screen}
                  alt="クエリパラメータが「KENRO」なので、「KENROさん、お誕生日おめでとう！」と画面に表示されている"
                />
                <p>
                  このサーバーの実装が以下のソースコードのようなとき、
                  どのようなリスクがあると考えられるでしょうか。
                </p>
              </DocumentStyle>
              <div className={styles.Code}>
                <pre className={styles.Code_Container}>
                  <code>{quiz1Code}</code>
                </pre>
              </div>
              <form className={styles.RadioForm} onSubmit={handleSubmit1}>
                <fieldset>
                  <DocumentStyle>
                    <div className={styles.RadioForm_OptionsWrapper}>
                      {quiz1Options.map((option) => {
                        return (
                          <>
                            <input
                              id={option.id}
                              type="radio"
                              value={option.id}
                              onChange={handleChange1}
                              checked={quiz1Answer === option.id}
                            />
                            <label htmlFor={option.id}>{option.text}</label>
                          </>
                        );
                      })}
                    </div>
                  </DocumentStyle>
                  {quiz1SubmitArea}
                </fieldset>
              </form>
            </Common2PaneSectionLayout>
          </Section>
        </div>

        {quiz2Dsiplay ? (
          <div className={styles.Quiz2}>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2 className={styles.Heading}>
                    <OptionalGatsbyImage
                      className={styles.Heading_Image}
                      data={data.heading2}
                      alt="挑戦 その2"
                    />
                    <div>
                      <span>認証完了後に遷移するページを</span>
                      <span>柔軟に指定できる</span>
                      <span>アプリケーション</span>
                    </div>
                  </h2>
                  <p>
                    以下のように、ログイン認証後クエリパラメータに入力されたURLに遷移する機能がとあるサイトで提供されていました。
                  </p>
                  <OptionalGatsbyImage
                    className={styles.Quiz_Image}
                    data={data.quiz2_description}
                    alt=""
                  />
                  <p>
                    このリダイレクト処理はサーバーサイドで実行されますが、クエリパラメータに入力されたURLに対して適切な対応がなされてない場合にはどのようなリスクがあると考えられるでしょうか。
                  </p>
                </DocumentStyle>
                <form className={styles.RadioForm} onSubmit={handleSubmit2}>
                  <fieldset>
                    <DocumentStyle>
                      <div className={styles.RadioForm_OptionsWrapper}>
                        {quiz2Options.map((option) => {
                          return (
                            <>
                              <input
                                id={option.id}
                                type="radio"
                                value={option.id}
                                onChange={handleChange2}
                                checked={quiz2Answer === option.id}
                              />
                              <label htmlFor={option.id}>{option.text}</label>
                            </>
                          );
                        })}
                      </div>
                    </DocumentStyle>

                    {quiz2SubmitArea}
                  </fieldset>
                </form>
              </Common2PaneSectionLayout>
            </Section>
          </div>
        ) : (
          ""
        )}

        {quiz3Display ? (
          <div className={styles.Quiz3}>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2 className={styles.Heading}>
                    <OptionalGatsbyImage
                      className={styles.Heading_Image}
                      data={data.heading3}
                      alt="挑戦 その3"
                    />
                    <div>
                      <span>SQLによって</span>
                      <span>商品検索を行う</span>
                      <span>アプリケーション</span>
                    </div>
                  </h2>
                  <p>
                    以下のように定義されているテーブルの中を検索する機能を持った
                    Web アプリケーションがあるとします。
                  </p>
                </DocumentStyle>
                <div className={styles.Code}>
                  <pre className={styles.Code_Container}>
                    <code>{quiz3Code1}</code>
                  </pre>
                </div>
                <DocumentStyle>
                  <p>
                    このテーブルを検索する際には available が TRUE
                    のものだけが表示されるようにアプリケーションが作られています。具体的には、検索時に以下のようなSQL文が発行されて、その実行結果が返されています。
                  </p>
                </DocumentStyle>
                <div className={clsx(styles.Code, styles.CodeWrap)}>
                  <pre className={styles.Code_Container}>
                    <code>{quiz3Code2}</code>
                  </pre>
                </div>
                <DocumentStyle>
                  <p>
                    しかし、このアプリケーションには入力値の処理に関する脆弱性が存在するため、available
                    が FALSE
                    の商品もアプリケーション中に表示することができてしまいます。演習環境で脆弱性を突き、available
                    が FALSE の商品を表示させることで{" "}
                    <code>flatt&#123;...&#125;</code>{" "}
                    形式の隠されたデータを奪取してください。
                  </p>
                </DocumentStyle>
                <div className={styles.MessageAndButtonWrapper}>
                  <a
                    href="https://kenro-lp-quiz.flatt.training/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      buttonSize="Large"
                      className={styles.ExerciseButton}
                    >
                      演習環境に移動
                    </Button>
                  </a>
                </div>
                <DocumentStyle>
                  <p>
                    奪取した <code>flatt&#123;...&#125;</code>{" "}
                    形式のデータを以下のフォームより送信することで今回のチャレンジは全問正解です！
                  </p>
                </DocumentStyle>
                <form onSubmit={handleSubmit3}>
                  <fieldset>
                    <DocumentStyle>
                      {quiz3ResultDisplay ? (
                        <div className={styles.MessageAndButtonWrapper}>
                          <p className={styles.FailedMessage}>
                            {quiz3Result ? "" : "残念！不正解です"}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className={styles.TextForm}>
                        <Input
                          onChange={handleChange3}
                          placeholder="flatt{placeholder_of_answer}"
                        />
                        <Button
                          buttonSize="Medium"
                          className={styles.PrimaryButton__TextForm}
                          type="submit"
                        >
                          解答を送信
                        </Button>
                      </div>
                    </DocumentStyle>
                  </fieldset>
                </form>
              </Common2PaneSectionLayout>
            </Section>
          </div>
        ) : (
          ""
        )}
      </div>
    </Kenro2PaneLayout>
  );
};

const Container: React.FC<Props> = (props) => {
  return (
    <>
      <Component {...props} />
    </>
  );
};

export const query = graphql`
  query Kenro2023NewGradsQuizPage {
    ogp: file(relativePath: { eq: "kenro/2023_new_grads_quiz/ogp.jpg" }) {
      publicURL
    }
    main: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/ogp.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    numbering: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/numbering.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heading1: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz1_heading.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heading2: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz2_heading.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heading3: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz3_heading.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    quiz_success: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz_success.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    quiz1_screen: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz1_screen.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    quiz2_description: file(
      relativePath: { eq: "kenro/2023_new_grads_quiz/quiz2_description.png" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default Container;
