import React, { PropsWithChildren } from "react";
import * as styles from "./index.module.scss";
import clsx from "clsx";

type Width = "normal" | "no-limit";
type Align = "center" | "left";

type Props = {
  width?: Width;
  align?: Align;
  className?: string;
};

export const DocumentStyle = (props: PropsWithChildren<Props>) => {
  const { children, width, align } = props;

  if (width == "no-limit" && align != "left") {
    return (
      <div
        className={clsx(styles.DocumentStyle, styles.DocumentStyle__NoLimit)}
      >
        {children}
      </div>
    );
  } else if (width == "no-limit" && align == "left") {
    return (
      <div
        className={clsx(
          styles.DocumentStyle,
          styles.DocumentStyle__NoLimit,
          styles.DocumentStyle__Left,
        )}
      >
        {children}
      </div>
    );
  } else if (width != "no-limit" && align == "left") {
    return (
      <div className={clsx(styles.DocumentStyle, styles.DocumentStyle__Left)}>
        {children}
      </div>
    );
  } else {
    return <div className={styles.DocumentStyle}>{children}</div>;
  }
};
