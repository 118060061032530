// extracted by mini-css-extract-plugin
export var CodeWrap = "index-module--CodeWrap--01hZC";
export var Code_Container = "index-module--Code_Container--jCwzw";
export var ExerciseButton = "index-module--ExerciseButton--i8O59";
export var FailedMessage = "index-module--FailedMessage--Itml-";
export var Heading = "index-module--Heading--P+2Yh";
export var Heading_Image = "index-module--Heading_Image--u7A3r";
export var Introduction = "index-module--Introduction--zMShZ";
export var Introduction_Decoration = "index-module--Introduction_Decoration--dwpKn";
export var Introduction_Description = "index-module--Introduction_Description--FAzNr";
export var KenroCommon2PaneSectionLayout = "index-module--KenroCommon2PaneSectionLayout--0p17Q";
export var MessageAndButtonWrapper = "index-module--MessageAndButtonWrapper--B6gtv";
export var PrimaryButton = "index-module--PrimaryButton--ox6bq";
export var PrimaryButton__TextForm = "index-module--PrimaryButton__TextForm--XIErb";
export var RadioForm = "index-module--RadioForm--Di7V0";
export var RadioForm_OptionsWrapper = "index-module--RadioForm_OptionsWrapper--5GlKR";
export var TextForm = "index-module--TextForm--eoPMF";
export var Top = "index-module--Top--tLVIy";
export var Top_MainImage = "index-module--Top_MainImage--u+Rpe";
export var Wrapper = "index-module--Wrapper--GhBuu";